import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Router } from '@angular/router';
import { FcmService } from './services/fcm.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  loggedIn: any;
  profile_id:any;

  constructor(
    private platform: Platform,
    private router: Router,
    private fcmService: FcmService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      // this.statusBar.styleDefault();
      this.fcmService.initPush();

    });
  }

  ngOnInit() {
    this.loggedIn = localStorage.getItem('loggedIn');      
  }

  logOut(){
    localStorage.removeItem('loggedIn');      
    localStorage.removeItem('perfil_id');      
    localStorage.removeItem('nombre');      
    localStorage.removeItem('usuario_id'); 
    localStorage.removeItem('profile_id'); 
    localStorage.removeItem('Refresh');      
    localStorage.clear();
    // location.reload();
    this.router.navigate(['/home']);
  }

}
