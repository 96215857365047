import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'folder/Inbox',
  //   pathMatch: 'full'
  // },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },  
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'dashboard/home',
    loadChildren: () => import('./dashboard/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'dashboard/customers/list',
    loadChildren: () => import('./dashboard/customers/list/list.module').then( m => m.ListPageModule)
  },
  {
    path: 'dashboard/users/list',
    loadChildren: () => import('./dashboard/users/list/list.module').then( m => m.ListPageModule)
  },
  {
    path: 'user-modal',
    loadChildren: () => import('./dashboard/users/user-modal/user-modal.module').then( m => m.UserModalPageModule)
  },
  {
    path: 'cutomer-modal',
    loadChildren: () => import('./dashboard/customers/cutomer-modal/cutomer-modal.module').then( m => m.CutomerModalPageModule)
  },
  {
    path: 'see-modal',
    loadChildren: () => import('./dashboard/customers/see-modal/see-modal.module').then( m => m.SeeModalPageModule)
  },
  {
    path: 'boat-modal',
    loadChildren: () => import('./dashboard/customers/boat-modal/boat-modal.module').then( m => m.BoatModalPageModule)
  },
  {
    path: 'dashboard/services/list',
    loadChildren: () => import('./dashboard/services/list/list.module').then( m => m.ListPageModule)
  },
  {
    path: 'service-modal',
    loadChildren: () => import('./dashboard/services/service-modal/service-modal.module').then( m => m.ServiceModalPageModule)
  },
  {
    path: 'dashboard/schedule/list',
    loadChildren: () => import('./dashboard/schedule/list/list.module').then( m => m.ListPageModule)
  },
  {
    path: 'dashboard/schedule/customers',
    loadChildren: () => import('./dashboard/schedule/customers/customers.module').then( m => m.CustomersPageModule)
  },
  {
    path: 'dashboard/schedule/customers-book',
    loadChildren: () => import('./dashboard/schedule/customers-book/customers-book.module').then( m => m.CustomersBookPageModule)
  },

  {
    path: 'dashboard/schedule/customers-date',
    loadChildren: () => import('./dashboard/schedule/customers-date/customers-date.module').then( m => m.CustomersDatePageModule)
  },

  {
    path: 'dashboard/schedule/schedule-book',
    loadChildren: () => import('./dashboard/schedule/schedule-book/schedule-book.module').then( m => m.ScheduleBookPageModule)
  },  

  {
    path: 'calendar-modal',
    loadChildren: () => import('./dashboard/schedule/calendar-modal/calendar-modal.module').then( m => m.CalendarModalPageModule)
  },
  {
    path: 'calendar-page',
    loadChildren: () => import('./dashboard/schedule/calendar-page/calendar-page.module').then( m => m.CalendarPagePageModule)
  },
  {
    path: 'dashboard/customer-service',
    loadChildren: () => import('./dashboard/customers/customer-service/customer-service.module').then( m => m.CustomerServicePageModule)
  },
  {
    path: 'dashboard/profile',
    loadChildren: () => import('./dashboard/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'update-profile',
    loadChildren: () => import('./dashboard/update-profile/update-profile.module').then( m => m.UpdateProfilePageModule)
  },
  {
    path: 'dashboard/detail-service/:id',
    loadChildren: () => import('./dashboard/customers/detail-service/detail-service.module').then( m => m.DetailServicePageModule)
  },
  {
    path: 'dashboard/chat/:id',
    loadChildren: () => import('./dashboard/customers/chat/chat.module').then( m => m.ChatPageModule)
  },
  {
    path: 'dashboard/user-service',
    loadChildren: () => import('./dashboard/users/user-service/user-service.module').then( m => m.UserServicePageModule)
  },   {
    path: 'dashboard/user-service-detail/:id',
    loadChildren: () => import('./dashboard/users/user-service-detail/user-service-detail.module').then( m => m.UserServiceDetailPageModule)
  },
  {
    path: 'clock',
    loadChildren: () => import('./dashboard/customers/clock/clock.module').then( m => m.ClockPageModule)
  },
  {
    path: 'dashboard/notification',
    loadChildren: () => import('./dashboard/notification/notification.module').then( m => m.NotificationPageModule)
  },
  {
    path: 'service-additional',
    loadChildren: () => import('./dashboard/services/service-additional/service-additional.module').then( m => m.ServiceAdditionalPageModule)
  },
  {
    path: 'services-modal',
    loadChildren: () => import('./dashboard/schedule/services-modal/services-modal.module').then( m => m.ServicesModalPageModule)
  },
  {
    path: 'boats-modal',
    loadChildren: () => import('./dashboard/schedule/boats-modal/boats-modal.module').then( m => m.BoatsModalPageModule)
  },
  {
    path: 'note-modal',
    loadChildren: () => import('./dashboard/schedule/note-modal/note-modal.module').then( m => m.NoteModalPageModule)
  },
  {
    path: 'dashboard/info',
    loadChildren: () => import('./dashboard/contact/info/info.module').then( m => m.InfoPageModule)
  },
  {
    path: 'additional-services',
    loadChildren: () => import('./dashboard/schedule/additional-services/additional-services.module').then( m => m.AdditionalServicesPageModule)
  },
  {
    path: 'dashboard/schedule/resumen-schedule',
    loadChildren: () => import('./dashboard/schedule/resumen-schedule/resumen-schedule.module').then( m => m.ResumenSchedulePageModule)
  },

  {
    path: 'dashboard/schedule/resumen-schedule-ok',
    loadChildren: () => import('./dashboard/schedule/resumen-schedule-ok/resumen-schedule-ok.module').then( m => m.ResumenScheduleOkPageModule)
  },

  {
    path: 'dashboard/schedule/schedule-book-new',
    loadChildren: () => import('./dashboard/schedule/schedule-book-new/schedule-book-new.module').then( m => m.ScheduleBookNewPageModule)
  },
    
  {
    path: 'dashboard/tickets/listado-tickets',
    loadChildren: () => import('./dashboard/tickets/listado-tickets/listado-tickets.module').then( m => m.ListadoTicketsPageModule)
  },
  {
    path: 'dashboard/tickets/new-ticket',
    loadChildren: () => import('./dashboard/tickets/new-ticket/new-ticket.module').then( m => m.NewTicketPageModule)
  },
  {
    path: 'dashboard/tickets/view-ticket',
    loadChildren: () => import('./dashboard/tickets/view-ticket/view-ticket.module').then( m => m.ViewTicketPageModule)
  },
  {
    path: 'dashboard/tickets/resumen-ticket',
    loadChildren: () => import('./dashboard/tickets/resumen-ticket/resumen-ticket.module').then( m => m.ResumenTicketPageModule)
  },
  {
    path: 'employees-modal',
    loadChildren: () => import('./dashboard/schedule/employees-modal/employees-modal.module').then( m => m.EmployeesModalPageModule)
  },
  {
    path: 'service-price-modal',
    loadChildren: () => import('./dashboard/schedule/service-price-modal/service-price-modal.module').then( m => m.ServicePriceModalPageModule)
  },
  {
    path: 'cal-modal',
    loadChildren: () => import('./dashboard/schedule/cal-modal/cal-modal.module').then( m => m.CalModalPageModule)
  },
  {
    path: 'customers-schedule-modal',
    loadChildren: () => import('./dashboard/schedule/customers-schedule-modal/customers-schedule-modal.module').then( m => m.CustomersScheduleModalPageModule)
  },
  {
    path: 'answer-modal',
    loadChildren: () => import('./dashboard/tickets/answer-modal/answer-modal.module').then( m => m.AnswerModalPageModule)
  },
  {
    path: 'clock/clock-record',
    loadChildren: () => import('./dashboard/customers/clock-record/clock-record.module').then( m => m.ClockRecordPageModule)
  },
  {
    path: 'registro',
    loadChildren: () => import('./registro/registro.module').then( m => m.RegistroPageModule)
  },
  {
    path: 'dashboard/customers/my-boats',
    loadChildren: () => import('./dashboard/customers/my-boats/my-boats.module').then( m => m.MyBoatsPageModule)
  },







];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
