import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgCalendarModule } from 'ionic2-calendar';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {AutosizeModule} from 'ngx-autosize';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';

import "@ionic/pwa-elements";
import { NgxImageCompressService } from 'ngx-image-compress';
import { CalendarModule } from 'ion2-calendar';
import { SwiperModule } from 'swiper/angular';


registerLocaleData(localeDe);


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    AutosizeModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule, 
    FormsModule,
    ReactiveFormsModule,   
    NgCalendarModule,
    SwiperModule,
    CalendarModule.forRoot({
      doneLabel: 'Save',
      closeIcon: true
    }),
    FormsModule,
  ],
  providers: [
    SplashScreen,
    Camera,
    NgxImageCompressService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
