import { Injectable } from '@angular/core';
// import {
//   Plugins,
//   PushNotification,
//   PushNotificationToken,
//   PushNotificationActionPerformed,
//   Capacitor
// } from '@capacitor/core'
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
// import { PushNotifications } from '@capacitor/push-notifications';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';

// const { PushNotifications } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class FcmService {

  constructor(private router: Router) { }
 
  public initPush() {
    if (Capacitor.getPlatform() !=='web') {
      console.log('dd=0');
      // this.registerNotifications();
      this.registerPush();
    }else{
      console.log('else');
      
    }
  }

  
 
  private registerPush() {
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });


    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: Token) => {
        localStorage.setItem('token_push',token.value); 
        // alert('Push registration success, token: ' + token.value);
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        // alert('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        // alert('Push received: ' + JSON.stringify(notification));
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        // alert('Push action performed: ' + JSON.stringify(notification));
      }
    );    
  }
 


  
  //   PushNotifications.addListener(
  //     'registration',
  //     (token: PushNotificationToken) => {
  //       console.log('My token: ' + JSON.stringify(token));
  //       localStorage.setItem('token_push',token.value); 
  //     }
  //   );
 
  //   PushNotifications.addListener('registrationError', (error: any) => {
  //     console.log('Error: ' + JSON.stringify(error));
  //   });
 
  //   PushNotifications.addListener(
  //     'pushNotificationReceived',
  //     async (notification: PushNotification) => {
  //       console.log('Push received: ' + JSON.stringify(notification));
  //     }
  //   );
 
  //   PushNotifications.addListener(
  //     'pushNotificationActionPerformed',
  //     async (notification: PushNotificationActionPerformed) => {
  //       const data = notification.notification.data;
  //       console.log('Action performed: ' + JSON.stringify(notification.notification));
  //       if (data.detailsId) {
  //         this.router.navigateByUrl(`/home/${data.detailsId}`);
  //       }
  //     }
  //   );
  // }
}